import _ from "lodash";
import { environment as baseEnvironment } from './environment.qa';

export const environment = _.merge({}, baseEnvironment, {
  environmentName: 'uat',
  production: false,
  log: false,
  flags: {
    useNewHeader: false
  },
  assetsStorageUrl: 'https://assets-embark-uat.ehr.com',
  invisibleReCaptchaSiteKey: "6Lc6K94aAAAAAOdLTjOMo2j8X44C6nQyjBY9SsPm",
  withChallengeReCaptchaSiteKey: "6LdSMt4aAAAAAIARFy_LvkYkpEi55r6pdIn4_OIR",
  appInsightsConfig: {
    instrumentationKey: '62341ccf-0f1f-40e1-a66e-660181d3fb61',
    environmentName: 'uat'
  },
  appInsightsConfigPro: {
    instrumentationKey: '66d2060f-a371-427e-89b2-2771d2547a03',
    environmentName: 'AzUATEM'
  },
  b2cRegistrationApiBaseUrl: 'https://n21pexb2cextuatappsvc.azurewebsites.net/api/',

  registrationApiBaseUrl: 'https://embark-uat-gw.ehr.com/api/Registration/',
  translationApiBaseUrl: 'https://embark-uat-gw.ehr.com/api/Registration/',
  configApiUrl: 'https://embarkpro-uatna.ehr.com/std-gateway/api/registrationConfig/',
  kbaBaseUrl: 'https://embark-uat-gw.ehr.com/api/kba/',
  loginFriendlyBaseUrl: 'https://signin-embark-uat.ehr.com/',
  loginEmbarkAppFriendlyBaseUrl: '',
  oneTrustConfig: {
    scriptSource: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    scriptId: '1833c667-beea-48f0-9bee-fad3b1bd9cf1-test',
    defaultLang: 'en',
    privacyNoticeGuid: 'bbb80b16-980d-443f-94f1-e348aca60056',
    cookieNoticeGuid: 'b8716e4f-acd5-4ffb-9217-eb31a9b3509b',
    termsOfUseGuid: '882e2831-64c6-4ca1-974b-d53591a973fb',
    noticeBundleGuid: 'c20a4882-10af-4ef8-8376-cbffed898dfa',
  },
  pushNotificationsApiBaseUrl: 'https://embarkpro-uatna.ehr.com/std-gateway/api/pushnotifications',
  redirectUserProfileEmbark: 'https://embark-uat.ehr.com/user-profile',
  identityVerification:{
    settlementPortalSDKUrl: 'https://idscan.mdp.wtwco.com?',
    primaryColor: '#7F35B2',
    baseUrl: 'https://poc.idscan.cloud/',
    cacheExpiry: '3600000',
    journeyDefinitionIdName: 'SingleAuthPOC'
  }
});

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.